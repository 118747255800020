<template>
  <div>
    <div class="row">
      <div class="col-12 my-2">
        <button
          @click="triggerFileInput"
          class="btn w-100 p-5 mt-2"
          :style="{
            border: '3px dashed gray',
          }"
        >
          Importez ici les images
        </button>
      </div>
    </div>
    <form @submit.prevent="createMedia" class="mt-4">
      <div class="row">
        <div>
          <input
            type="file"
            id="fileInput"
            ref="fileInput"
            name="fileInput"
            accept="image/*"
            multiple
            @change="handleImagesUpload"
          />
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-md-4 mb-3 my-2">
              <label for="name" class="form-label"> Titre :</label>
              <input
                type="text"
                id="name"
                v-model="media.title"
                class="form-control"
                required
              />
            </div>

            <div class="col-md-4 mb-3 my-2">
              <label for="name" class="form-label"> Date d'événement : </label>
              <input
                type="date"
                id="publicationDate"
                v-model="media.publicationDate"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-stretch">
        <div
          v-for="(preview, index) in media.imagePreviews"
          :key="index"
          class="col-xl-3 px-xl-2 py-xl-3"
        >
          <div class="card rounded-0 h-100">
            <img :src="preview" alt="Image Preview" class="w-100" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mb-3">
          <button type="submit" class="btn btn-primary">
            Enregistrer les images
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  compoenents: {
  },
  data() {
    return {
      media: {
        title: "",
        images: [],
        imageUrls: [],
        imagePreviews: [], // Add this line to initialize the imagePreviews array
      },
    };
  },
  methods: {
    createMedia() {
      const formData = new FormData();
      formData.append("title", this.media.title);
      formData.append("publicationDate", this.media.publicationDate);

      for (let i = 0; i < this.media.images.length; i++) {
        formData.append("images[]", this.media.images[i]);
      }

      this.$store.dispatch("media/create", formData);
    },

    handleImagesUpload(event) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.media.images.push(file);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.media.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },

    triggerFileInput() {
      // Programmatically trigger the hidden file input
      this.$refs.fileInput.click();
    },
  },
};
</script>
